var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.galleryShow,
      expression: "galleryShow"
    }],
    staticClass: "galleryModal"
  }, [_c('div', {
    staticClass: "gallery"
  }, [_c('span', {
    staticClass: "gallery-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:galleryShow', false);
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
      "fill": "white"
    }
  })])]), _c('div', {
    staticClass: "gallery-pic"
  }, [_c('img', {
    attrs: {
      "src": _vm.gallery.active,
      "alt": "book1"
    }
  })]), _c('span', {
    staticClass: "prev",
    on: {
      "click": function ($event) {
        return _vm.sliderPrev(_vm.gallery.picIndex - 1);
      }
    }
  }, [_vm._v("❮")]), _c('span', {
    staticClass: "next",
    on: {
      "click": function ($event) {
        return _vm.sliderNext(_vm.gallery.picIndex + 1);
      }
    }
  }, [_vm._v("❯")]), _c('div', {
    staticClass: "thumb-row"
  }, _vm._l(_vm.gallery.list, function (item) {
    return _c('div', {
      key: item.index,
      class: ['thumb', {
        'active': _vm.gallery.picIndex === item.index
      }],
      on: {
        "click": function ($event) {
          _vm.gallery.picIndex = item.index;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.src,
        "alt": `book${item.index}`
      }
    })]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }